import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";

import Tap from "./components/Tap";
import Friend from "./components/Friends";
import UserInfo from "./components/UserInfo";
import Earn from "./components/Earn";
import Stone from "./components/Stone";
import Loader from "./components/Loader";
import Toast from "./components/Toast";
import Menu from "./components/Menu";
import axios from "axios";
import MainAdmin from "./components/admin/MainAdmin";
import PlayONYourPhone from "./components/PlayOnPhone";
import Coin from "./assets/coin.svg";

const Root = "https://back.galaxyum.in";
//const Root = "http://localhost:5000";

function App() {
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isPhone, setIsPhone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toasts, setToasts] = useState([]);
  const [stoneValue, setStoneValue] = useState();
  const [minePower, setMinePower] = useState();
  const [passivEraned, setPassiveEraned] = useState(0);
  const [isAdmin, setIsAdmin] = useState();

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };
  const toastCondition = (text, color) => {
    const newToast = { id: Date.now(), text: text, color: color };
    setToasts((prevToasts) => {
      const updatedToasts = [...prevToasts, newToast];
      if (updatedToasts.length > 3) {
        updatedToasts.shift(); // Remove the oldest toast if more than 3
      }
      return updatedToasts;
    });
    setTimeout(() => {
      removeToast(newToast.id);
    }, 5000);
  };

  useEffect(() => {
    const fetching = async () => {
      if (window.Telegram) {
        const { WebView } = window.Telegram;
        const userDevice = WebView.initParams.tgWebAppPlatform;
        const initParams = WebView.initParams;
        const tgWebAppData = initParams.tgWebAppData;
        const urlParams = new URLSearchParams(tgWebAppData);
        const userParam = urlParams.get("user");
        const refParam = urlParams.get("start_param");
        const user = JSON.parse(userParam);
        const refId = JSON.parse(refParam);
        const userIdFromUrl = user.id;
        const refid = refId;
        const userName = user.first_name;
        if (userDevice !== "android" && userDevice !== "ios") {
          setLoading(false);
          return;
        }
        setIsPhone(true);
        if (userIdFromUrl) {
          setUserId(userIdFromUrl);
          localStorage.setItem("userId", userIdFromUrl);
          try {
            const response = await axios.get(`${Root}/user/${userIdFromUrl}`);
            if (response.data) {
              if (response.data.autoClickerActive) {
                try {
                  const autoMineReq = await axios.put(
                    `${Root}/user/autoclickmined/${response.data.userId}`
                  );
                  setPassiveEraned(autoMineReq.data.automined);
                  const responseAfter = await axios.get(
                    `${Root}/user/${userIdFromUrl}`
                  );
                  setUserData(responseAfter.data);
                  localStorage.setItem("point", responseAfter.data.score);
                  setMinePower(responseAfter.data.minePower);
                  const stoneV = await axios.get(
                    `${Root}/user/stonevalue/${userIdFromUrl}`
                  );
                  setStoneValue(stoneV.data.stoneValue);
                } catch (error) {
                  toastCondition(`Error in fetching Data`, "danger");
                }
              }
              if (response.data.minePower > 0) {
                try {
                  const mined = await axios.put(
                    `${Root}/user/mined/${userIdFromUrl}`
                  );
                  setPassiveEraned((prevAmount) => {
                    const newAmount = prevAmount + mined.data.minedscore;
                    return newAmount;
                  });
                } catch (error) {
                  toastCondition("Error in fetching Data", "danger");
                }
              }
              setUserData(response.data);
              const stoneV = await axios.get(
                `${Root}/user/stonevalue/${userIdFromUrl}`
              );
              setStoneValue(stoneV.data.stoneValue);
              setMinePower(response.data.minePower);
              localStorage.setItem("point", response.data.score);
            }
          } catch (error) {
            if (
              error.response.status === 404 &&
              error.response.data.message === "User not found"
            ) {
              try {
                const data = {
                  userId: userIdFromUrl,
                  referrerId: refid,
                  name: userName,
                };
                const response = await axios.post(`${Root}/register`, data);
                setUserData(response.data.user);
              } catch (error) {
                toastCondition(`Error in fetching Data`, "Danger");
              }
            } else {
              toastCondition(`Error in fetching Data`, "Danger");
            }
          } finally {
            setInterval(() => {
              setLoading(false);
            }, 1000);
          }
        }
      }
    };
    fetching();
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      console.log("online");
    } else {
      localStorage.setItem("lastOnline", Date.now());
    }
  }, [navigator.onLine]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userIdFromUrl = urlParams.get("userId");

    if (userIdFromUrl) {
      setUserId(userIdFromUrl);
      localStorage.setItem("userId", userIdFromUrl);
    } else {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        setUserId(storedUserId);
      } else {
        toastCondition("User ID is missing", "Danger");
        setLoading(false);
      }
    }
  }, []);

  const getStoneValue = async () => {
    try {
      const response = await axios.get(
        `${Root}/user/stonevalue/${userData.userId}`
      );
      setStoneValue(response.data.stoneValue);
    } catch (error) {
      toastCondition("Error in fetching data");
    }
  };

  const fetchMinePower = async () => {
    try {
      const response = await axios.get(
        `${Root}/user/minepower/${userData.userId}`
      );
      setMinePower(response.data.minepower);
    } catch (error) {
      toastCondition("Error in fetching data");
    }
  };

  if (!isPhone) {
    return <PlayONYourPhone />;
  }

  if (loading) {
    return <Loader />;
  }

  const closePassivErnaed = () => {
    const target = document.getElementById("show-passive-score");
    target.style.display = "none";
  };
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return Math.floor(num);
    }
  };
  return (
    <div className='App'>
      <div className='toasts-container'>
        {toasts.map((toast) => (
          <Toast key={toast.id} text={toast.text} color={toast.color} />
        ))}
      </div>
      <UserInfo
        userData={userData}
        Root={Root}
        stoneValue={stoneValue}
        minePower={minePower}
      />
      {userData && (userData.autoClickerActive || userData.minePower > 0) && (
        <div className='passive-eraned' id='show-passive-score'>
          <div className='total-passive-eraned'>
            <img src={Coin} />
            <h1> {formatNumber(passivEraned)}</h1>
          </div>
          <h2>
            Was earned by the auto cliker{" "}
            {userData.minePower > 0 && "and miners"}
          </h2>
          <button onClick={closePassivErnaed}>Thanks</button>
        </div>
      )}

      <Routes>
        <Route
          path='/'
          element={
            <Tap
              userData={userData}
              toastCondition={toastCondition}
              Root={Root}
            />
          }
        />
        <Route
          path='/friend'
          element={
            <Friend
              userId={userId}
              toastCondition={toastCondition}
              Root={Root}
            />
          }
        />
        <Route
          path='/earn'
          element={
            <Earn
              toastCondition={toastCondition}
              userData={userData}
              Root={Root}
            />
          }
        />
        <Route
          path='/stone'
          element={
            <Stone
              userId={userData.userId}
              toastCondition={toastCondition}
              Root={Root}
              getStoneValue={getStoneValue}
              fetchMinePower={fetchMinePower}
            />
          }
        />
        <Route path='/admin' element={<MainAdmin />} />
      </Routes>
      <Menu />
    </div>
  );
}

export default App;
// scp -r build/* root@65.108.158.163:/var/www/react.galaxyum.in
// scp -r dist/* root@65.108.158.163:/var/www/react.galaxyum.in
