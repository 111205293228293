import { useEffect, useState } from "react";

const UpdateConfirm = ({ id, onClickFunction, onClose }) => {
  const [text, setText] = useState();

  const getText = () => {
    if (id === 1) setText("Are you sure you want to update the tap point?");
    if (id === 2) setText("Are you sure you want to get full energy?");
    if (id === 3) setText("Are you sure you want to update the auto cliker ?");
  };

  useEffect(() => {
    getText();
  }, [id]);
  return (
    <>
      <div className='update-confirm'>
        <h2>{text}</h2>
        <div className='update-confirm-btn'>
          <button
            onClick={() => {
              onClickFunction(id);
            }}
            className='yes'
          >
            YES
          </button>
          <button onClick={onClose} className='no'>
            NO
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateConfirm;
