import beaconchip from "../assets/gems/beaconchip.svg";
import beehelix from "../assets/gems/beehelix.svg";
import bifocal from "../assets/gems/bifocal.svg";
import circulo from "../assets/gems/circulo.svg";
import cosmocubes from "../assets/gems/cosmocubes.svg";
import cosmodisc from "../assets/gems/cosmodisc.svg";
import cubestar from "../assets/gems/cubestar.svg";
import cubex from "../assets/gems/cubex.svg";
import cubispheres from "../assets/gems/cubispheres.svg";
import cuboidal from "../assets/gems/cuboidal.svg";
import axios from "axios";
import { useState, useEffect } from "react";
import coinLogo from "../assets/coin.svg";
import { AiFillCloseCircle } from "react-icons/ai";
import miner1 from "../assets/miner/1.svg";
import miner2 from "../assets/miner/2.svg";
import miner3 from "../assets/miner/3.svg";
import miner4 from "../assets/miner/4.svg";
import miner5 from "../assets/miner/5.svg";
import miner6 from "../assets/miner/6.svg";
import miner7 from "../assets/miner/7.svg";
import miner8 from "../assets/miner/8.svg";
import miner9 from "../assets/miner/9.svg";
import miner10 from "../assets/miner/10.svg";
const Stone = (props) => {
  const [stonePage, setStonePage] = useState(true);
  const [isBuying, setIsBuying] = useState(false);
  const [sotneIdIsBuying, setStoneIdIsBuing] = useState();
  const [stoneNameIsBuying, setStoneNameIsBuying] = useState();
  const [stoneValue, setStoneValue] = useState();
  const [stoneCost, setStoneCost] = useState();
  const [userData, setUserData] = useState();
  const [lastBuyTime, setLastBuyTime] = useState(0);
  const [isBuyingMiner, setIsBuyingMiner] = useState(false);
  const [canActive, setCanActive] = useState(true);
  const [stoneToBuy, setStoneToBuy] = useState(null);
  const [minerToActive, setMinerToActive] = useState([]);
  const [minerToActiveId, setMinerToAtciveId] = useState();
  const userId = props.userId;
  const Root = props.Root;

  const stones = [
    {
      name: "beaconchip",
      cost: 10000,
      id: 1,
      img: beaconchip,
      value: 1,
    },
    {
      name: "beehelix",
      cost: 15000,
      id: 2,
      img: beehelix,
      value: 2,
    },
    {
      name: "bifocal",
      cost: 25000,
      id: 3,
      img: bifocal,
      value: 3,
    },
    {
      name: "circulo",
      cost: 35000,
      id: 4,
      img: circulo,
      value: 4,
    },
    {
      name: "cosmocubes",
      cost: 50000,
      id: 5,
      img: cosmocubes,
      value: 6,
    },
    {
      name: "cosmodisc",
      cost: 75000,
      id: 6,
      img: cosmodisc,
      value: 8,
    },
    {
      name: "cubestar",
      cost: 100000,
      id: 7,
      img: cubestar,
      value: 10,
    },
    {
      name: "cubex",
      cost: 150000,
      id: 8,
      img: cubex,
      value: 15,
    },
    {
      name: "cubispheres",
      cost: 200000,
      id: 9,
      img: cubispheres,
      value: 20,
    },
    {
      name: "cuboidal",
      cost: 300000,
      id: 10,
      img: cuboidal,
      value: 25,
    },
  ];
  const miners = [
    {
      name: "Miner",
      id: 1,
      power: 10,
      img: miner1,
      requiredStones: [
        {
          stoneId: 1,
          amount: 2,
        },
        {
          stoneId: 2,
          amount: 1,
        },
      ],
      costForUpdate: 10000,
      timeForUpdate: 5,
    },
    {
      name: "Miner",
      id: 2,
      power: 20,
      img: miner2,
      requiredStones: [
        {
          stoneId: 2,
          amount: 3,
        },
      ],
      costForUpdate: 20000,
      timeForUpdate: 10,
    },
    {
      name: "Miner",
      id: 3,
      power: 50,
      img: miner3,
      requiredStones: [
        {
          stoneId: 3,
          amount: 2,
        },
        {
          stoneId: 4,
          amount: 1,
        },
      ],
      costForUpdate: 30000,
      timeForUpdate: 15,
    },
    {
      name: "Miner",
      id: 4,
      power: 100,
      img: miner4,
      requiredStones: [
        {
          stoneId: 4,
          amount: 2,
        },
        {
          stoneId: 5,
          amount: 1,
        },
      ],
      costForUpdate: 50000,
      timeForUpdate: 20,
    },
    {
      name: "Miner",
      id: 5,
      power: 200,
      img: miner5,
      requiredStones: [
        {
          stoneId: 5,
          amount: 2,
        },
        {
          stoneId: 6,
          amount: 1,
        },
      ],
      costForUpdate: 60000,
      timeForUpdate: 25,
    },
    {
      name: "Miner",
      id: 6,
      power: 500,
      img: miner6,
      requiredStones: [
        {
          stoneId: 6,
          amount: 3,
        },
      ],
      costForUpdate: 100000,
      timeForUpdate: 30,
    },
    {
      name: "Miner",
      id: 7,
      power: 1000,
      img: miner7,
      requiredStones: [
        {
          stoneId: 6,
          amount: 2,
        },
        {
          stoneId: 7,
          amount: 1,
        },
      ],
      costForUpdate: 300000,
      timeForUpdate: 35,
    },
    {
      name: "Miner",
      id: 8,
      power: 5000,
      img: miner8,
      requiredStones: [
        {
          stoneId: 7,
          amount: 4,
        },
      ],
      costForUpdate: 500000,
      timeForUpdate: 40,
    },
    {
      name: "Miner",
      id: 9,
      power: 10000,
      img: miner9,
      requiredStones: [
        {
          stoneId: 8,
          amount: 2,
        },
        {
          stoneId: 9,
          amount: 1,
        },
      ],
      costForUpdate: 1000000,
      timeForUpdate: 50,
    },
    {
      name: "Miner",
      id: 10,
      power: 15000,
      img: miner10,
      requiredStones: [
        {
          stoneId: 9,
          amount: 1,
        },
        {
          stoneId: 10,
          amount: 2,
        },
      ],
      costForUpdate: 5000000,
      timeForUpdate: 60,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${Root}/user/${userId}`);

      setUserData(response.data);
    };
    fetchData();
  }, [isBuying, isBuyingMiner, userData]);

  const addCommas = (number) => {
    const strNumber = number.toString();

    const parts = strNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

    const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas + decimalPart;
  };

  const openBuyStones = async (id, name, value, cost) => {
    setStoneIdIsBuing(id);
    setStoneNameIsBuying(name);
    setStoneValue(value);
    setStoneCost(cost);
    setIsBuying(true);
    const ston = stones.find((s) => s.id === id);
    setStoneToBuy(ston);
  };

  const closeBuyStone = () => {
    setIsBuying(false);
  };

  const buyStone = async () => {
    const currentTime = Date.now();
    if (currentTime - lastBuyTime < 5000) {
      props.toastCondition("Please give it a moment", "warning");
      return;
    }
    setLastBuyTime(currentTime);
    try {
      const data = {
        name: stoneNameIsBuying,
        stoneId: sotneIdIsBuying,
        value: stoneValue,
        cost: stoneCost,
      };
      await axios.put(`${Root}/user/buystone/${userId}`, data);
      const currentScore = localStorage.getItem("point");
      navigator.vibrate([40]);
      localStorage.setItem("point", currentScore - stoneCost);
      props.toastCondition(`You purchased 1 ${stoneNameIsBuying}`, "success");
      props.getStoneValue();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "An error occurred";
      props.toastCondition(errorMessage, "warning");
    }
  };

  const minerPage = () => {
    setStonePage(false);
    setIsBuying(false);
  };

  const goStonePage = () => {
    setStonePage(true);
  };

  const showActiveMiner = (minerId) => {
    setIsBuyingMiner(true);
    const miner = miners.find((m) => m.id === minerId);
    setMinerToActive(miner);

    const canActivate = miner.requiredStones.every((requiredStone) => {
      const userStone = userData.stones.find(
        (stone) => stone.stoneId === requiredStone.stoneId
      );

      return userStone && userStone.amount >= requiredStone.amount;
    });

    setCanActive(canActivate);
  };

  const activeMiner = async (minerId) => {
    const miner = userData.miner.find((m) => m.minerId === minerId);
    if (miner) {
      props.toastCondition("This miner is already active", "warning");
    } else {
      const minerChosed = miners.find((m) => m.id === minerId);
      try {
        const data = { minerId: minerChosed.id, power: minerChosed.power };
        axios.put(`${Root}/user/activeminer/${userData.userId}`, data);
        navigator.vibrate([40]);
        props.toastCondition("Miner is activated", "success");
        setTimeout(() => {
          props.fetchMinePower();
        }, 500);
      } catch (error) {
        props.toastCondition("Somthing is wrong please try again", "danger");
      }
    }
  };

  const updateMiner = async (minerId) => {
    const miner = userData.miner.find((m) => m.minerId === minerId);
    if (!miner) {
      props.toastCondition("This miner is not activated", "warning");
    } else {
      const minerChosed = miners.find((m) => m.id === minerId);
      const lastUpdate = new Date(miner.lastUpdate).getTime();
      const now = Date.now();
      const timeForUpdate = minerChosed.timeForUpdate * 60 * 1000;
      const timeElapsed = now - lastUpdate;
      const timeLeft = timeForUpdate - timeElapsed;
      if (timeLeft > 0) {
        props.toastCondition("You can not update at the moment", "warning");
      } else {
        try {
          const data = {
            minerId: minerId,
            power: minerChosed.power,
            cost: minerChosed.costForUpdate,
          };
          axios.put(`${Root}/user/updateminer/${userData.userId}`, data);
          navigator.vibrate([40]);
          const currentScore = localStorage.getItem("point");
          const newScore = currentScore - minerChosed.costForUpdate;
          localStorage.setItem("piont", newScore);
          props.toastCondition("Miner is updated", "success");
          setTimeout(() => {
            props.fetchMinePower();
          }, 500);
        } catch (error) {
          props.toastCondition("Somthing is wrong please try again", "danger");
          console.log(error);
        }
      }
    }
  };

  const closeActiveMiner = () => {
    setIsBuyingMiner(false);
  };

  const renderButton = (minerId) => {
    const miner = userData.miner.find((m) => m.minerId === minerId);
    const minerInfo = miners.find((m) => m.id === minerId);
    if (miner) {
      const lastUpdate = new Date(miner.lastUpdate).getTime();
      const now = Date.now();
      const timeForUpdate = minerInfo.timeForUpdate * 60 * 1000;
      const timeElapsed = now - lastUpdate;
      const timeLeft = timeForUpdate - timeElapsed;
      if (timeLeft > 0) {
        const minutesLeft = Math.ceil(timeLeft / 1000 / 60);
        return <button disabled>{`Wait ${minutesLeft} minutes`}</button>;
      }
      return (
        <button onClick={() => updateMiner(minerId)}>
          Update Miner {addCommas(minerInfo.costForUpdate)}
        </button>
      );
    } else {
      return (
        <button
          className={canActive ? "" : "deactivebtn"}
          onClick={() => activeMiner(minerToActiveId)}
        >
          {canActive ? "Active" : "You don't have required Stones"}
        </button>
      );
    }
  };

  return (
    <>
      {isBuyingMiner && (
        <div className='buy-miner'>
          <AiFillCloseCircle onClick={closeActiveMiner} />
          <h3>
            Activate this miner to get {minerToActive.power} more points in an
            hour
          </h3>
          <h2>Required Stones</h2>
          <div className='requred-stone'>
            {minerToActive.requiredStones.map((stone, index) => {
              const stoneData = stones.find((s) => s.id === stone.stoneId);
              return (
                <div className='stone-info' key={index}>
                  {stoneData ? (
                    <img src={stoneData.img} alt='' />
                  ) : (
                    <p>Image not found</p>
                  )}
                  <p>Amount: {stone.amount}</p>
                </div>
              );
            })}
          </div>
          {renderButton(minerToActiveId)}
        </div>
      )}
      {isBuying && (
        <div className='confirm-buy-stone'>
          <AiFillCloseCircle onClick={closeBuyStone} />
          <h1>Buy {stoneToBuy.name}</h1>
          <img src={stoneToBuy.img} alt='' />
          <div className='stone-buy-cost'>
            <img src={coinLogo} alt='' /> <h2>{addCommas(stoneToBuy.cost)}</h2>
          </div>

          <button onClick={buyStone}>Buy</button>
        </div>
      )}
      {userData && userData.referrals < 3 && (
        <div className='stone-cover'>
          <h2>You must have more than 3 invites</h2>
        </div>
      )}
      <div className='stone'>
        <div className='blob1'></div>
        <div className='blob2'></div>
        <div className='blob3'></div>
        <div className='chose-part'>
          <h3 className={`${stonePage ? "active" : ""}`} onClick={goStonePage}>
            {" "}
            Buy Stones
          </h3>
          <h3 className={`${stonePage ? "" : "active"}`} onClick={minerPage}>
            Miner
          </h3>
        </div>
        {stonePage ? (
          <div className='gems-box'>
            {stones.map((stone) => (
              <div
                className='geme-box'
                key={stone.id}
                onClick={() => {
                  openBuyStones(stone.id, stone.name, stone.value, stone.cost);
                }}
              >
                <img src={stone.img} alt='' />
                <p>{stone.name}</p>
                <p>{addCommas(stone.cost)}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className='miners-box'>
            {userData &&
              miners.map((miner) => {
                const activeMiner = userData.miner.find(
                  (m) => m.minerId === miner.id
                );
                const boxClass = activeMiner ? "miner-box active" : "miner-box";
                const minerPower = activeMiner
                  ? activeMiner.power * activeMiner.level
                  : miner.power;
                const minerLevel = activeMiner ? activeMiner.level : 0;
                return (
                  <div
                    className={boxClass}
                    key={miner.id}
                    onClick={() => {
                      showActiveMiner(miner.id);
                      setMinerToAtciveId(miner.id);
                    }}
                  >
                    <img src={miner.img} alt='' />
                    <p>Power : {minerPower} P/H</p>
                    <p>Level : {minerLevel}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
};

export default Stone;
