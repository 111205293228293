import axios from "axios";
import { useEffect, useState } from "react";
import beaconchip from "../assets/gems/beaconchip.svg";
import beehelix from "../assets/gems/beehelix.svg";
import bifocal from "../assets/gems/bifocal.svg";
import circulo from "../assets/gems/circulo.svg";
import cosmocubes from "../assets/gems/cosmocubes.svg";
import cosmodisc from "../assets/gems/cosmodisc.svg";
import cubestar from "../assets/gems/cubestar.svg";
import cubex from "../assets/gems/cubex.svg";
import cubispheres from "../assets/gems/cubispheres.svg";
import cuboidal from "../assets/gems/cuboidal.svg";
import { AiFillCloseCircle } from "react-icons/ai";
import close from "../assets/close.svg";

const UserStones = ({ Root, userData, isOpen }) => {
  const [userStones, setUserStones] = useState([]);
  const stones = [
    {
      name: "beaconchip",
      id: 1,
      img: beaconchip,
      value: 1,
    },
    {
      name: "beehelix",
      id: 2,
      img: beehelix,
      value: 2,
    },
    {
      name: "bifocal",
      id: 3,
      img: bifocal,
      value: 3,
    },
    {
      name: "circulo",
      id: 4,
      img: circulo,
      value: 4,
    },
    {
      name: "cosmocubes",
      id: 5,
      img: cosmocubes,
      value: 5,
    },
    {
      name: "cosmodisc",
      id: 6,
      img: cosmodisc,
      value: 6,
    },
    {
      name: "cubestar",
      id: 7,
      img: cubestar,
      value: 7,
    },
    {
      name: "cubex",
      id: 8,
      img: cubex,
      value: 8,
    },
    {
      name: "cubispheres",
      id: 9,
      img: cubispheres,
      value: 9,
    },
    {
      name: "cuboidal",
      id: 10,
      img: cuboidal,
      value: 10,
    },
  ];

  useEffect(() => {
    const fetching = async () => {
      if (userData) {
        try {
          const response = await axios.get(
            `${Root}/user/stones/${userData.userId}`
          );
          setUserStones(response.data.userStones);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetching();
  }, []);

  return (
    <>
      <div className='user-stones'>
        <img src={close} onClick={() => isOpen(false)} />
        <div className='list-stones'>
          {userStones &&
            userStones.map((stone) => {
              const stoneImg = stones.find((s) => s.id === stone.stoneId);
              return (
                <div className='list-stone-box'>
                  <img src={stoneImg.img} alt='' />
                  <p>{stone.name}</p>
                  <p>Amount: {stone.amount}</p>
                  <p>Amount used:</p>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default UserStones;
