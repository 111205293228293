import { useEffect, useState } from "react";
import axios from "axios";
import { useInterval } from "usehooks-ts";
import UserStones from "./UserStones";

const UserInfo = (props) => {
  const userData = props.userData;
  const [stoneValue, setStoneValue] = useState();
  const [minePower, setMinePower] = useState();
  const [showUserStones, setShowUserStones] = useState(false);
  const Root = props.Root;
  const getLevelName = (level) => {
    if (level === 1) return "Novice Nebula";
    if (level === 2) return "Stellar Sprout";
    if (level === 3) return "Cosmic Cadet";
    if (level === 4) return "Galactic Explorer";
    if (level === 5) return "Star Voyager";
    if (level === 6) return "Planetary Pioneer";
    if (level === 7) return "Comet Conqueror";
    if (level === 8) return "Asteroid Adventurer";
    if (level === 9) return "Celestial Commander";
    if (level === 10) return "Orbit Overlord";
    if (level === 11) return "Quasar Quester";
    if (level === 12) return "Galaxium Guardian";
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(2) + "K";
    } else {
      return Math.floor(num);
    }
  };

  return (
    <>
      {showUserStones && (
        <UserStones
          Root={props.Root}
          userData={props.userData}
          isOpen={setShowUserStones}
        />
      )}
      <div className='userInfo'>
        <div className='user-name' style={{ textAlign: "center" }}>
          <p style={{ marginLeft: "15px" }}>{getLevelName(userData.level)}</p>
          <h3>{userData.name}</h3>
        </div>
        <div
          className='tap-point'
          onClick={() => {
            setShowUserStones(true);
          }}
        >
          <p>Stone value:</p>
          <h3>{props.stoneValue || 0}</h3>
        </div>
        <div className='aouto-cliker-info'>
          <p>Mine Power :</p>
          <h3>{formatNumber(props.minePower) || 0} P/H</h3>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
