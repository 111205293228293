import { LuCoins } from "react-icons/lu";
import { PiHandTap } from "react-icons/pi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaUserFriends } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GiStonePile } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import earn from "../assets/earn.svg";
import inavite from "../assets/inavite.svg";
import tapplay from "../assets/tapplay.svg";
import stonpart from "../assets/stonpart.svg";

const Menu = () => {
  const userLocation = useLocation().pathname;

  return (
    <>
      <div className='menu'>
        <div className='menu-items'>
          <div className={userLocation == "/" ? "active" : ""}>
            <Link to='/'>
              {" "}
              <img src={tapplay} alt='' />
              <p>Tap</p>
            </Link>
          </div>
          <div className={userLocation == "/earn" ? "active" : ""}>
            <Link to='/earn'>
              {" "}
              <img src={earn} alt='' />
              <p>Earn</p>
            </Link>
          </div>
          <div className={userLocation == "/friend" ? "active" : ""}>
            <Link to='/friend'>
              <img src={inavite} alt='' />
              <p>Friend</p>
            </Link>
          </div>
          <div className={userLocation == "/stone" ? "active" : ""}>
            <Link to='/stone'>
              <img src={stonpart} alt='' />
              <p>Stone</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
