import { IoClose } from "react-icons/io5";

const Toast = (props) => {
  const text = props.text;
  const color = props.color;
  const toastClass = `toast ${color}`;

  return (
    <>
      <div className={`toast ${color}`}>
        <p>{text}</p>
        <IoClose />
      </div>
    </>
  );
};

export default Toast;
