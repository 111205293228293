import Qr from "../assets/QRcode.png";
const PlayONYourPhone = ({ userDev }) => {
  return (
    <>
      <div className='play-on-your-phone'>
        <h1>!OPEN ON YOUR PHONE!</h1>
        <img src={Qr} alt='qr-code' />
        <h1>{userDev}</h1>
      </div>
    </>
  );
};

export default PlayONYourPhone;
