const Loader = () => {
  return (
    <>
      <div className='load-page'>
        <div class='loader'></div>
        <div className='loadre-text'>...GALAXIUM...</div>
      </div>
    </>
  );
};

export default Loader;
