import giftBox from "../assets/gift.svg";
import coinLogo from "../assets/coin.svg";
import userLogo from "../assets/user.svg";
import { IoCopy } from "react-icons/io5";
import { useState, useEffect } from "react";
import axios from "axios";

const Friend = (props) => {
  const userId = props.userId;
  const [referrals, setReferrals] = useState([]);
  const Root = props.Root;
  const botUrl = "https://t.me/Galaxiumtap_bot";

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const response = await axios.get(`${Root}/user/${userId}/referrals`);
        setReferrals(response.data.referrals);
      } catch (error) {
        console.error("Error fetching referrals:", error);
      }
    };

    fetchReferrals();
  }, [userId]);

  const copyRefLink = () => {
    const refLink = `${botUrl}/start?startapp=${userId}`;
    navigator.clipboard.writeText(refLink);
    navigator.vibrate([40]);
    props.toastCondition("Link copied successfully", "success");
  };

  const shareRefInTg = () => {
    const refLink = `${botUrl}/start?startapp=${userId}`;
    const tgUrl = `https://t.me/share/url?url=${refLink}`;
    window.location.href = tgUrl;
  };

  return (
    <>
      <div className='friend'>
        <h1>Invite Friends</h1>
        <p>You will receive bonuses</p>
        <div className='ref-info'>
          <div className='eran-each-ref'>
            <img src={giftBox} alt='' />
            <h3>Invite a friend</h3>
            <div className='ref-earn'>
              <img src={coinLogo} alt='' />
              <p>+5,000</p>
              <p>For you</p>
            </div>
          </div>
        </div>
        <div className='copy-link'>
          <div onClick={shareRefInTg}>
            <p>Invite Friend</p>
          </div>
          <button onClick={copyRefLink}>
            <IoCopy />
          </button>
        </div>
        <div className='friend-list'>
          <h4>List of your friends</h4>
          {referrals.map((referral, index) => (
            <div className='friend-info' key={index}>
              <img src={userLogo} alt='' />
              <p>{referral.name}</p>
              <div className='friend-score'>
                <span>{referral.level}</span>
                <img src={coinLogo} alt='' />
                <span style={{ marginLeft: "22px" }}>{referral.score}</span>
              </div>
            </div>
          ))}
        </div>

        <div className='user-invaited'></div>
      </div>
    </>
  );
};

export default Friend;
