import { AiFillCloseCircle } from "react-icons/ai";
import energyLogo from "../assets/energy.svg";
import robot from "../assets/robot.svg";
import tapLogo from "../assets/tap.svg";
import { useState } from "react";
import UpdateConfirm from "./confirm/UpdateConfirm";

const Update = (props) => {
  const autoClickerLevel = props.autoClickerLevel;
  const [showConfirm, setShowConfirm] = useState(false);
  const [id, setId] = useState();
  const tapPoint = props.tapPoint;

  const addCommas = (number) => {
    const strNumber = number.toString();

    const parts = strNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

    const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas + decimalPart;
  };

  const onClickConfirm = (id) => {
    if (id === 1) props.updateTapPoint();
    if (id === 3) props.autoClicker();
    setShowConfirm(false);
  };

  const showConfirmPage = (id) => {
    setShowConfirm(true);
    setId(id);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <>
      {showConfirm && (
        <UpdateConfirm
          id={id}
          onClickFunction={onClickConfirm}
          onClose={closeConfirm}
        />
      )}
      <div className={`update ${props.isVisible ? "slide-up" : "slide-down"}`}>
        <AiFillCloseCircle onClick={props.closeBoostPage} />
        <div className='update-options'>
          <div
            className='update-tapponit'
            onClick={() => {
              showConfirmPage(1);
            }}
          >
            <img src={tapLogo} alt='' />
            <div className='update-options-content'>
              <h4>update tap point </h4>
            </div>
            <p>{addCommas((tapPoint - 4) * tapPoint * 2500)}</p>
          </div>
          <div className='get-energy'>
            <img src={energyLogo} alt='' />
            <div className='update-options-content' onClick={props.resetEnergy}>
              <h4>Full energy</h4>
            </div>
            <span>6/{localStorage.getItem("resetCount")}</span>
            <p>2,000</p>
          </div>
          <div
            className='auto-clicker'
            onClick={() => {
              showConfirmPage(3);
            }}
          >
            <img src={robot} alt='' />
            <div className='update-options-content'>
              <h4>
                {autoClickerLevel > 0
                  ? "Update Aouto Cliker"
                  : "Activate Auto Cliker Bot"}
              </h4>
            </div>
            <p>{addCommas((autoClickerLevel + 1) * 5000)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update;
