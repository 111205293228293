import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Update from "./Update";
import coinLogo from "../assets/coin.svg";
import rocket from "../assets/rocket.svg";
import thunder from "../assets/thunder.svg";
import { useInterval } from "usehooks-ts";
import L1 from "../assets/levels/1.svg";
import L2 from "../assets/levels/2.svg";
import L3 from "../assets/levels/3.svg";
import L4 from "../assets/levels/4.svg";
import L5 from "../assets/levels/5.svg";
import L6 from "../assets/levels/6.svg";
import L7 from "../assets/levels/7.svg";
import L8 from "../assets/levels/8.svg";
import L9 from "../assets/levels/9.svg";
import L10 from "../assets/levels/10.svg";
import L11 from "../assets/levels/11.svg";
import L12 from "../assets/levels/12.svg";

const Tap = (props) => {
  const [countTap, setCountTap] = useState(0);
  const [tapPoint, setTapPoint] = useState();
  const [energy, setEnergy] = useState();
  const [maxEnergy, setMaxEnergy] = useState();
  const [nextLevelPoint, setNextLevelPoint] = useState();
  const [previousLevelPoint, setPreviousLevelPoint] = useState();
  const [showUpdate, setShowUpdate] = useState(false);
  const [animateClose, setAnimateClose] = useState(false);
  const [autoClickerActive, setAutoClickerActive] = useState(false);
  const [autoClickerLevel, setAutoClickerLevel] = useState(1);
  const [levelName, setLevelName] = useState();
  const [level, setLevel] = useState(1);
  const [tapNum, setTapNum] = useState(0);
  const [tapAnimations, setTapAnimations] = useState([]);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [lastResetETime, setLastResetETime] = useState(0);
  const [lastAutoClickerTime, setLastAutoClickerTime] = useState(0);
  const [lastMined, setLastMined] = useState();
  const userData = props.userData;
  const Root = props.Root;
  const resetLimit = 6;
  const resetCost = 2000;
  const resetTimeKey = "resetTime";
  const resetCountKey = "resetCount";
  const images = {
    1: L1,
    2: L2,
    3: L3,
    4: L4,
    5: L5,
    6: L6,
    7: L7,
    8: L8,
    9: L9,
    10: L10,
    11: L11,
    12: L12,
  };
  const gem = images[userData.level];

  useEffect(() => {
    if (userData) {
      setTapPoint(userData.tapPoint || 5);

      const maxEnergy = userData.level * 1000;
      setMaxEnergy(maxEnergy || 1000);
      setLevel(userData.level || 1);

      const storedEnergy = localStorage.getItem("energy");
      const lastUpdateEnergy = localStorage.getItem("lastUpdateEnergy");
      if (parseInt(storedEnergy)) {
        const newEnergy = ((Date.now() - lastUpdateEnergy) / 1000) * 5;
        const final = Math.min(
          Math.round(storedEnergy) + Math.round(newEnergy),
          maxEnergy
        );
        setEnergy(parseInt(final, 10));
      } else {
        setEnergy(maxEnergy);
        localStorage.setItem("energy", maxEnergy);
      }

      setNextLevelPoint(getNextLevelPoint(userData.level));
      setPreviousLevelPoint(getPreviousLevelPoint(userData.level));
      setAutoClickerActive(userData.autoClickerActive || false);
      setAutoClickerLevel(userData.autoClickerLevel || 0);
    }
  }, [userData]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const storedPoints = localStorage.getItem("point");
  //     getLevelName(userData.level);
  //     if (storedPoints) {
  //       setCountTap(parseInt(storedPoints, 10));
  //     } else {
  //       setCountTap(userData.score);
  //       localStorage.setItem("point", userData.score);
  //     }
  //   }, 100);

  //   return () => clearInterval(intervalId);
  // }, []);

  useInterval(() => {
    const fetchingScore = () => {
      const storedPoints = localStorage.getItem("point");
      getLevelName(userData.level);
      if (storedPoints) {
        setCountTap(parseInt(storedPoints, 10));
      } else {
        setCountTap(userData.score);
        localStorage.setItem("point", userData.score);
      }
    };
    fetchingScore();
  }, 100);

  useEffect(() => {
    const interval = setInterval(() => {
      setEnergy((prevEnergy) => {
        const newEnergy = Math.min(prevEnergy + 15 * 0.5, maxEnergy);
        localStorage.setItem("energy", newEnergy);
        localStorage.setItem("lastUpdateEnergy", Date.now());
        return newEnergy;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [tapPoint, maxEnergy]);

  useEffect(() => {
    const checkResetCount = () => {
      const currentTime = Date.now();
      const lastResetTime = parseInt(localStorage.getItem(resetTimeKey), 10);
      let resetCount = localStorage.getItem(resetCountKey) || resetLimit;

      if (
        !lastResetTime ||
        currentTime - lastResetTime >= 24 * 60 * 60 * 1000
      ) {
        resetCount = resetLimit;
        localStorage.setItem(resetTimeKey, currentTime);
        localStorage.setItem(resetCountKey, resetCount);
      }
    };

    checkResetCount();
    const intervalId = setInterval(checkResetCount, 60 * 60 * 1000); // Check every hour

    return () => clearInterval(intervalId);
  }, []);

  const handleTouchStart = (event) => {
    const numTouches = event.touches.length;
    navigator.vibrate([20]);
    tapCounter(event.touches, numTouches);
    const img = document.querySelector(".img");
    img.classList.add("clicked");
    setTimeout(() => {
      img.classList.remove("clicked");
    }, 200);
  };

  //------------------------------------------------

  // const mutation = useMutation(() => sendTapPoints(userData.userId, Root), {
  //   onSuccess: (data) => {
  //     console.log("Tap points sent successfully", data);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //     props.toastCondition("Error", "danger");
  //   },
  // });

  //-----------------------------Submit Tap points---------------------------------------
  const sendingRef = useRef(false);
  useInterval(async () => {
    if (!sendingRef.current) {
      if (tapNum) {
        sendingRef.current = true;
        const currentTapNum = tapNum;
        try {
          const response = await axios.put(
            `${Root}/user/tap/${userData.userId}`,
            { tapNum: tapNum }
          );
          if (response.status === 200) {
            setTapNum(tapNum - currentTapNum);
          }
        } catch (error) {
          console.log(error);
        } finally {
          sendingRef.current = false;
        }
      }
    }
  }, 1200);
  //-----------------------------Submit Tap points---------------------------------------

  //-----------------------------Calculate Token Mined-----------------------------------
  useInterval(() => {
    const miner = async () => {
      if (userData.minePower > 0) {
        try {
          const response = await axios.get(`${Root}/user/${userData.userId}`);
          const user = response.data;
          if (user.minePower > 0) {
            const response = await axios.put(
              `${Root}/user/mined/${user.userId}`
            );
            localStorage.setItem("point", response.data.score);
          }
        } catch (error) {
          console.log(error);
          props.toastCondition("Error in fetching", "danger");
        }
      }
    };
    miner();
  }, 1000);
  //-----------------------------Calculate Token Mined-----------------------------------

  //-----------------------------Calculate Aouto Clicker---------------------------------
  useInterval(async () => {}, 4500);
  //-----------------------------Calculate Aouto Clicker---------------------------------
  const tapCounter = async (touches, numTouches = 1) => {
    const pointsToAdd = numTouches * tapPoint;
    if (energy >= tapPoint) {
      handleTapAnimation(touches);
      setTapNum((prevNum) => {
        const newNum = prevNum + numTouches;
        return newNum;
      });
      setCountTap((prevCount) => {
        const newCount = prevCount + pointsToAdd;
        increaseNumberAnimation(prevCount, prevCount + pointsToAdd);
        localStorage.setItem("point", newCount);

        return newCount;
      });
      //mutation.mutate();
      setEnergy((prevEnergy) => {
        const newEnergy = prevEnergy - pointsToAdd;
        localStorage.setItem("energy", newEnergy);
        return newEnergy;
      });
    } else {
      props.toastCondition("Not enough energy to tap!", "warning");
    }
  };
  const handleTapAnimation = (touches) => {
    const animations = Array.from(touches).map((touch) => ({
      id: Date.now() + Math.random(),
      x: touch.clientX,
      y: touch.clientY,
    }));

    setTapAnimations((prevAnimations) => [...prevAnimations, ...animations]);

    animations.forEach((animation) => {
      setTimeout(() => {
        setTapAnimations((prevAnimations) =>
          prevAnimations.filter((anim) => anim.id !== animation.id)
        );
      }, 700); // Duration of animation in milliseconds
    });
  };

  const getNextLevelPoint = (level) => {
    if (level === 1) return 10000;
    if (level === 2) return 25000;
    if (level === 3) return 100000;
    if (level === 4) return 250000;
    if (level === 5) return 600000;
    if (level === 6) return 1000000;
    if (level === 7) return 5000000;
    if (level === 8) return 50000000;
    if (level === 9) return 200000000;
    if (level === 10) return 500000000;
    if (level === 11) return 1000000000;
  };
  const getPreviousLevelPoint = (level) => {
    if (level === 1) return 0;
    if (level === 2) return 10000;
    if (level === 3) return 25000;
    if (level === 4) return 100000;
    if (level === 5) return 250000;
    if (level === 6) return 600000;
    if (level === 7) return 1000000;
    if (level === 8) return 5000000;
    if (level === 9) return 50000000;
    if (level === 10) return 200000000;
    if (level === 11) return 500000000;
  };

  const getLevelName = (level) => {
    if (level === 1) setLevelName("Novice Nebula");
    if (level === 2) setLevelName("Stellar Sprout");
    if (level === 3) setLevelName("Cosmic Cadet");
    if (level === 4) setLevelName("Galactic Explorer");
    if (level === 5) setLevelName("Star Voyager");
    if (level === 6) setLevelName("Planetary Pioneer");
    if (level === 7) setLevelName("Comet Conqueror");
    if (level === 8) setLevelName("Asteroid Adventurer");
    if (level === 9) setLevelName("Celestial Commander");
    if (level === 10) setLevelName("Orbit Overlord");
    if (level === 11) setLevelName("Quasar Quester");
    if (level === 12) setLevelName("Galaxium Guardian");
  };

  const progressToNextLevel =
    ((countTap - previousLevelPoint) / (nextLevelPoint - previousLevelPoint)) *
    100;

  const openBoostPage = () => {
    setShowUpdate(true);
    setAnimateClose(false);
  };

  const closeBoostPage = () => {
    setAnimateClose(true);
    setTimeout(() => {
      setShowUpdate(false);
    }, 500);
  };
  //---------------------------------------------------UPDATE PAGE FUNCTIONS----------------------------------------------------------//
  const updateTapPoint = async () => {
    const currentTime = Date.now();
    if (currentTime - lastUpdateTime < 5000) {
      props.toastCondition("Please give it a moment", "warning");
      return;
    }
    setLastUpdateTime(currentTime);
    const price = (tapPoint - 4) * tapPoint * 2500;
    if (countTap >= price) {
      const newTapPoint = tapPoint + 1;
      const newScore = countTap - price;
      props.toastCondition("Tap point +1", "success");

      setCountTap(newScore);
      setTapPoint(newTapPoint);
      localStorage.setItem("point", newScore);
      navigator.vibrate([100]);
      try {
        await axios.put(`${Root}/user/updatetappoint/${userData.userId}`, {
          price: price,
        });
      } catch (error) {
        console.log(error);
        props.toastCondition("Error in updating tap point.", "danger");
      }
    } else {
      props.toastCondition("Not enough points to update tap point.", "danger");
    }
  };

  const resetEnergy = async () => {
    const currentTime = Date.now();
    if (currentTime - lastResetETime < 5000) {
      props.toastCondition("Please give it a moment", "warning");
      return; // Return early if less than 5 seconds have passed
    }
    setLastResetETime(currentTime);

    const lastResetTime = parseInt(localStorage.getItem(resetTimeKey), 10);
    let resetCount = localStorage.getItem(resetCountKey) || resetLimit;

    if (!lastResetTime || currentTime - lastResetTime >= 24 * 60 * 60 * 1000) {
      resetCount = resetLimit;
      localStorage.setItem(resetTimeKey, currentTime);
    }
    if (energy > energy / 10) {
      if (resetCount >= 1) {
        if (countTap >= resetCost) {
          const newScore = countTap - resetCost;
          setCountTap(newScore);

          try {
            await axios.put(`${Root}/user/resetenergy/${userData.userId}`);
            setEnergy(maxEnergy);
            resetCount -= 1;
            localStorage.setItem(resetCountKey, resetCount);
            localStorage.setItem(resetTimeKey, currentTime);
            const currentScore = localStorage.getItem("point");
            const newScore = currentScore - resetCost;
            localStorage.setItem("piont", newScore);
            props.toastCondition("Energy reset was successful!", "success");
          } catch (error) {
            props.toastCondition("Try again", "warning");
            console.error("Error resetting energy:", error);
          }
        } else {
          props.toastCondition("Not enough points to reset energy.", "danger");
        }
      } else {
        props.toastCondition(
          "You have reached the reset limit for today.",
          "warning"
        );
      }
    } else {
      props.toastCondition("Energy is already full ", "warning");
    }
  };

  const autoClicker = async () => {
    const currentTime = Date.now();
    if (currentTime - lastAutoClickerTime < 5000) {
      props.toastCondition("Please give it a moment", "warning");
      return;
    }
    setLastAutoClickerTime(currentTime);
    if (countTap >= 5000 * autoClickerLevel) {
      const newScore = countTap - 5000 * autoClickerLevel;
      setCountTap(newScore);
      setAutoClickerActive(true);
      if (!userData.autoClickerActive) {
        try {
          await axios.put(`${Root}/user/activeautoclicker/${userData.userId}`);
          localStorage.setItem("piont", newScore);
          props.toastCondition("Auto clicker bot activated", "success");
        } catch (error) {
          props.toastCondition("Error in activation", "warning");
        }
      } else {
        if (userData.autoClickerLevel < 5) {
          try {
            axios.put(`${Root}/user/updateautoClickerLevel/${userData.userId}`);
            setAutoClickerLevel(autoClickerLevel + 1);

            props.toastCondition(
              `Auto clicker level is updated to level ${autoClickerLevel + 1}`,
              "success"
            );
          } catch (error) {
            props.toastCondition("somthing is wrong,try again", "danger");
            console.log(error);
          }
        } else {
          props.toastCondition("Auto cliker level is MAX", "warning");
        }
      }
    } else {
      props.toastCondition(
        "Not enough points to activate auto clicker.",
        "danger"
      );
    }
  };

  //---------------------------------------------------UPDATE PAGE FUNCTIONS----------------------------------------------------------//

  const addCommas = (number) => {
    const strNumber = number.toString();

    const parts = strNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

    const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas + decimalPart;
  };

  function increaseNumberAnimation(currentNum, endNumber, speed = 5) {
    const element = document.getElementById("count-num");

    if (!element) return;

    /* A dataset variable that is added to the animated element*/
    const animationRunning = JSON.parse(
      element.dataset.animationRunning ?? false
    );

    if (animationRunning) return;

    element.dataset.animationRunning = true;

    incNbrRec(currentNum, endNumber, element, speed);
  }

  /*A recursive function to increase the number.*/
  function incNbrRec(currentNumber, endNumber, element, speed) {
    if (currentNumber <= endNumber) {
      element.innerHTML = addCommas(currentNumber);
      setTimeout(function () {
        incNbrRec(currentNumber + 1, endNumber, element, speed);
      }, speed); //Delay a bit before calling the function again.
    } else {
      element.dataset.animationRunning = false;
    }
  }

  return (
    <>
      {showUpdate && (
        <Update
          resetEnergy={resetEnergy}
          updateTapPoint={updateTapPoint}
          closeBoostPage={closeBoostPage}
          isVisible={!animateClose}
          autoClickerLevel={autoClickerLevel}
          autoClicker={autoClicker}
          tapPoint={tapPoint}
        />
      )}
      <div className='tap-page'>
        <div className='header'></div>
        <div className='info'></div>
        <div className='total-coin'>
          <img src={coinLogo} alt='' />
          <h2 id='count-num'>{addCommas(countTap)}</h2>
        </div>

        <div className='level-range'>
          <div className='level-info'>
            <p style={{ marginLeft: "4px" }}>{levelName}</p>
            <p>{level}/12</p>
          </div>

          <progress
            type='range'
            min='0'
            max='100'
            value={progressToNextLevel}
            readOnly
            className='level-progress'
          ></progress>
        </div>
        <div className='tap' onTouchStart={handleTouchStart}>
          <img src={gem} alt='' disbled className='img' />
        </div>
        <div className='energy'>
          <div style={{ display: "flex", marginLeft: "0" }}>
            <img
              src={thunder}
              style={{ width: "40px", height: "40px", marginTop: "10px" }}
            />
            <p>
              {Math.round(energy)}/{maxEnergy}
            </p>
          </div>
          <div
            style={{ display: "flex", marginRight: "10px" }}
            onClick={openBoostPage}
          >
            <img
              src={rocket}
              style={{
                marginRight: "10px",
                color: "#ffa000",
              }}
            />
            <p>Boost</p>
          </div>
        </div>
        {tapAnimations.map((anim) => (
          <div
            key={anim.id}
            className='tap-effect'
            style={{ top: anim.y, left: anim.x }}
          >
            +{tapPoint}
          </div>
        ))}
      </div>
    </>
  );
};

export default Tap;
