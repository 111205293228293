import { IoIosArrowForward } from "react-icons/io";
import telegramLoog from "../assets/telegram.svg";
import coinLogo from "../assets/coin.svg";
import xLogo from "../assets/X.svg";
import dsicordLogo from "../assets/dsicord.svg";
import friendLogo from "../assets/freind.svg";
import check from "../assets/check.svg";
import dailyreward from "../assets/dailyreward.svg";
import youtube from "../assets/youtube.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import day1 from "../assets/days/day1.svg";
import day2 from "../assets/days/day2.svg";
import day3 from "../assets/days/day3.svg";
import day4 from "../assets/days/day4.svg";
import day5 from "../assets/days/day5.svg";
import day6 from "../assets/days/day6.svg";
import day7 from "../assets/days/day7.svg";
import day8 from "../assets/days/day8.svg";
import day9 from "../assets/days/day9.svg";
import day10 from "../assets/days/day10.svg";
import { AiFillCloseCircle } from "react-icons/ai";

const Earn = (props) => {
  const userData = props.userData;
  const Root = props.Root;
  const [tasks, setTasks] = useState([
    { id: 1, text: "Join our Channel", logo: telegramLoog, reward: 10000 },
    { id: 2, text: "Follow us on Twitter", logo: xLogo, reward: 10000 },
    { id: 3, text: "Repost our Tweets", logo: xLogo, reward: 5000 },
    {
      id: 4,
      text: "Subscribe to our Youtube channel",
      logo: youtube,
      reward: 20000,
    },
    {
      id: 10,
      text: "Invite 5 friends",
      logo: friendLogo,
      reward: 20000,
      requiredInvites: 5,
    },
    {
      id: 11,
      text: "Invite 10 friends",
      logo: friendLogo,
      reward: 50000,
      requiredInvites: 10,
    },
    {
      id: 5,
      text: "Daily Reward",
      logo: dailyreward,
      reward: "?",
      isDaily: true,
    },
  ]);
  const [finishedTasks, setFinishedTasks] = useState([]);
  const [loadingTask, setLoadingTask] = useState(null);
  const [showDailyReward, setShowDailyReward] = useState(false);
  const rewards = [
    { points: 100, image: day1 },
    { points: 500, image: day2 },
    { points: 1000, image: day3 },
    { points: 2000, image: day4 },
    { points: 5000, image: day5 },
    { points: 10000, image: day6 },
    { points: 15000, image: day7 },
    { points: 20000, image: day8 },
    { points: 25000, image: day9 },
    { points: 30000, image: day10 },
  ];

  const [currentDay, setCurrentDay] = useState(0);
  const [lastClaimTime, setLastClaimTime] = useState(null);
  const [claimed, setClaimed] = useState(false);

  useEffect(() => {
    const storedFinishedTasks = localStorage.getItem("finishedTasks");
    if (storedFinishedTasks) {
      const parsedFinishedTasks = JSON.parse(storedFinishedTasks);
      setFinishedTasks(parsedFinishedTasks);
      setTasks((prevTasks) =>
        prevTasks.filter(
          (task) =>
            !parsedFinishedTasks.some(
              (finishedTask) => finishedTask.id === task.id
            )
        )
      );
    }

    const lastClaimTime = localStorage.getItem("lastClaimTime");
    if (lastClaimTime) {
      const timeElapsed = Date.now() - new Date(lastClaimTime).getTime();
      if (timeElapsed >= 24 * 60 * 60 * 1000) {
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.isDaily ? { ...task, available: true } : task
          )
        );
      }
    }
  }, []);

  const completeTask = async (taskId) => {
    setLoadingTask(taskId);

    const taskIndex = tasks.findIndex((task) => task.id === taskId);
    const taskToComplete = tasks[taskIndex];

    if (
      taskToComplete.requiredInvites &&
      userData.referrals < taskToComplete.requiredInvites
    ) {
      props.toastCondition(
        `You need to invite at least ${taskToComplete.requiredInvites} friends to complete this task.`,
        "warning"
      );
      setLoadingTask(null);
      return;
    } else if (taskId === 10 || taskId === 11) {
      if (userData.referrals >= 5) {
        try {
          const amount = 20000;
          await axios.put(`${Root}/user/task/${userData.userId}`, {
            amount: amount,
            taskId: taskId,
          });
          const updatedTasks = tasks.filter((task) => task.id !== taskId);
          const updatedFinishedTasks = [...finishedTasks, taskToComplete];

          setTasks(updatedTasks);
          setFinishedTasks(updatedFinishedTasks);
          const currentPoint = localStorage.getItem("point");
          const newPoint = amount + currentPoint;
          localStorage.setItem("point", newPoint);
          localStorage.setItem(
            "finishedTasks",
            JSON.stringify(updatedFinishedTasks)
          );
          setLoadingTask(null);
          navigator.vibrate([40]);
          props.toastCondition("Task done", "success");
        } catch (error) {
          props.toastCondition("Task failed", "danger");
        }
      } else if (userData.referrals >= 10) {
        try {
          const amount = 20000;
          await axios.put(`${Root}/user/task/${userData.userId}`, {
            amount: amount,
            taskId: taskId,
          });
          const updatedTasks = tasks.filter((task) => task.id !== taskId);
          const updatedFinishedTasks = [...finishedTasks, taskToComplete];

          setTasks(updatedTasks);
          setFinishedTasks(updatedFinishedTasks);
          const currentPoint = localStorage.getItem("point");
          const newPoint = amount + currentPoint;
          localStorage.setItem("point", newPoint);
          localStorage.setItem(
            "finishedTasks",
            JSON.stringify(updatedFinishedTasks)
          );
          setLoadingTask(null);
          navigator.vibrate([40]);
          props.toastCondition("Task done", "success");
        } catch (error) {
          props.toastCondition("Task failed", "danger");
        }
      } else {
        props.toastCondition("Task failed", "danger");
      }
    }

    // Call the appropriate function based on the task
    switch (taskId) {
      case 1:
        joinTelegramChannel(taskId);
        break;
      case 2:
        followTwitter(taskId);
        break;
      case 3:
        repost(taskId);
        break;
      case 4:
        subscribeYoutube(taskId);
        break;
      case 5:
        setShowDailyReward(true);
        return;
      default:
        break;
    }
  };

  useEffect(() => {
    // Load finished tasks from localStorage when the component mounts
    const storedFinishedTasks = localStorage.getItem("finishedTasks");
    if (storedFinishedTasks) {
      setFinishedTasks(JSON.parse(storedFinishedTasks));
    }
  }, []);

  const joinTelegramChannel = async (taskId) => {
    const channelUrl = "https://t.me/galaxiumCommuinty";
    window.location.href = channelUrl;
    try {
      const amount = 10000;
      await axios.put(`${Root}/user/task/${userData.userId}`, {
        amount: amount,
        taskId: taskId,
      });
      const taskIndex = tasks.findIndex((task) => task.id === taskId);
      const taskToComplete = tasks[taskIndex];
      const updatedTasks = tasks.filter((task) => task.id !== taskId);
      const updatedFinishedTasks = [...finishedTasks, taskToComplete];

      setTasks(updatedTasks);
      setFinishedTasks(updatedFinishedTasks);
      const currentPoint = localStorage.getItem("point");
      const newPoint = amount + currentPoint;
      localStorage.setItem("point", newPoint);
      localStorage.setItem(
        "finishedTasks",
        JSON.stringify(updatedFinishedTasks)
      );
      setLoadingTask(null);
      navigator.vibrate([40]);
      props.toastCondition("Task done", "success");
    } catch (error) {
      props.toastCondition("Task failed", "warning");
    }
  };
  const followTwitter = async (taskId) => {
    const twitterUrl = "https://x.com/XGalaxiumX";
    window.Telegram.WebApp.openLink(twitterUrl);
    try {
      const amount = 10000;
      await axios.put(`${Root}/user/task/${userData.userId}`, {
        amount: amount,
        taskId: taskId,
      });
      const taskIndex = tasks.findIndex((task) => task.id === taskId);
      const taskToComplete = tasks[taskIndex];
      const updatedTasks = tasks.filter((task) => task.id !== taskId);
      const updatedFinishedTasks = [...finishedTasks, taskToComplete];

      setTasks(updatedTasks);
      setFinishedTasks(updatedFinishedTasks);
      const currentPoint = localStorage.getItem("point");
      const newPoint = amount + currentPoint;
      localStorage.setItem("point", newPoint);
      localStorage.setItem(
        "finishedTasks",
        JSON.stringify(updatedFinishedTasks)
      );
      setLoadingTask(null);
      navigator.vibrate([40]);
      props.toastCondition("Task done", "success");
    } catch (error) {
      props.toastCondition("Task failed", "warning");
    }
  };

  const subscribeYoutube = async (taskId) => {
    const youtubeAppUrl = "https://www.youtube.com/@galaxium_Official";
    window.Telegram.WebApp.openLink(youtubeAppUrl);
    try {
      const amount = 20000;
      await axios.put(`${Root}/user/task/${userData.userId}`, {
        amount: amount,
        taskId: taskId,
      });
      const taskIndex = tasks.findIndex((task) => task.id === taskId);
      const taskToComplete = tasks[taskIndex];
      const updatedTasks = tasks.filter((task) => task.id !== taskId);
      const updatedFinishedTasks = [...finishedTasks, taskToComplete];

      setTasks(updatedTasks);
      setFinishedTasks(updatedFinishedTasks);
      const currentPoint = localStorage.getItem("point");
      const newPoint = amount + currentPoint;
      localStorage.setItem("point", newPoint);
      localStorage.setItem(
        "finishedTasks",
        JSON.stringify(updatedFinishedTasks)
      );
      setLoadingTask(null);
      navigator.vibrate([40]);
      props.toastCondition("Task done", "success");
    } catch (error) {
      props.toastCondition("Task failed", "warning");
    }
  };

  const repost = async (taskId) => {
    const twittUrl =
      "https://x.com/XGalaxiumX/status/1823371594107941049https://x.com/XGalaxiumX/status/1823371594107941049";
    window.Telegram.WebApp.openLink(twittUrl);
    try {
      const amount = 5000;
      await axios.put(`${Root}/user/task/${userData.userId}`, {
        amount: amount,
        taskId: taskId,
      });
      const taskIndex = tasks.findIndex((task) => task.id === taskId);
      const taskToComplete = tasks[taskIndex];
      const updatedTasks = tasks.filter((task) => task.id !== taskId);
      const updatedFinishedTasks = [...finishedTasks, taskToComplete];

      setTasks(updatedTasks);
      setFinishedTasks(updatedFinishedTasks);
      const currentPoint = localStorage.getItem("point");
      const newPoint = amount + currentPoint;
      localStorage.setItem("point", newPoint);
      localStorage.setItem(
        "finishedTasks",
        JSON.stringify(updatedFinishedTasks)
      );
      setLoadingTask(null);
      navigator.vibrate([40]);
      props.toastCondition("Task done", "success");
    } catch (error) {
      props.toastCondition("Task failed", "warning");
    }
  };

  // const joinInDiscord = async () => {
  //   const discordUrl = "";
  //   window.location.href = discordUrl;
  //   try {
  //     const amount = 10000;
  //     await axios.put(`${Root}/user/task/${userData.userId}`, {
  //       amount: amount,
  //       taskId: taskId,
  //     });
  //   } catch (error) {
  //     props.toastCondition("Task is failed", "warning");
  //   }
  // };

  useEffect(() => {
    const storedDay = localStorage.getItem("currentDay");
    const storedLastClaimTime = localStorage.getItem("lastClaimTime");

    if (storedDay) setCurrentDay(Number(storedDay));
    if (storedLastClaimTime) setLastClaimTime(new Date(storedLastClaimTime));
  }, []);

  useEffect(() => {
    if (lastClaimTime) {
      const timeElapsed = Date.now() - lastClaimTime.getTime();
      if (timeElapsed > 36 * 60 * 60 * 1000) {
        setCurrentDay(0);
        localStorage.setItem("currentDay", 0);
      }
    }
  }, [lastClaimTime]);

  const handleClaimReward = async () => {
    const now = new Date();
    const timeElapsed = now - new Date(lastClaimTime);

    if (timeElapsed < 24 * 60 * 60 * 1000) {
      props.toastCondition(
        "You can only claim once every 24 hours.",
        "warning"
      );
      return;
    }

    const dayScore = rewards[currentDay].points;
    const data = {
      dayScore: dayScore,
    };
    try {
      await axios.put(`${Root}/user/dailyreward/${userData.userId}`, data);
      const currentPoint = localStorage.getItem("point");
      const newPoint = currentPoint + dayScore;
      localStorage.setItem("point", newPoint);
      localStorage.setItem("lastClaimTime", now.toISOString());
      setLastClaimTime(now);

      const nextDay = (currentDay + 1) % rewards.length;
      setCurrentDay(nextDay);
      localStorage.setItem("currentDay", nextDay);

      props.toastCondition(
        `You have claimed ${rewards[currentDay].points} points!`,
        "success"
      );
      setClaimed(true);
      setLoadingTask(null);
    } catch (error) {
      console.error("Error claiming reward:", error);
      props.toastCondition("Error claiming reward!", "danger");
      setLoadingTask(false);
    }
  };
  const addCommas = (number) => {
    const strNumber = number.toString();

    const parts = strNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

    const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas + decimalPart;
  };

  return (
    <>
      {showDailyReward && (
        // <DailyReward
        //   onClose={() => setShowDailyReward(false)}
        //   userData={userData}
        //   toastCondition={props.toastCondition}
        //   Root={props.Root}
        //   setLoadingTask={setLoadingTask}
        // />
        <div className='daily-reward'>
          <button
            onClick={() => {
              setShowDailyReward(false);
            }}
          >
            <AiFillCloseCircle />
          </button>
          <img src={dailyreward} alt='' />
          <h1 style={{ marginTop: "-25px" }}>Daily Rewards</h1>
          <p style={{ marginTop: "-15px" }}>
            Accrue coins for logging into the game daily without skipping
          </p>
          <div className='rewards'>
            {rewards.map((reward, index) => (
              <div
                key={index}
                className={`reward-day ${
                  index === currentDay ? "active" : ""
                } ${index < currentDay ? "green" : ""}`}
                onClick={index === currentDay ? handleClaimReward : null}
              >
                <img src={reward.image} alt={`Day ${index + 1}`} />
                <p>{addCommas(reward.points)}</p>
              </div>
            ))}
            <div className={`come-back ${claimed ? "completed" : ""}`}>
              <h3>
                {claimed
                  ? `You have claimed ${addCommas(
                      rewards[currentDay].points
                    )} coins!`
                  : currentDay === rewards.length - 1 && claimed
                  ? `Come back tomorrow`
                  : `Claim today's reward`}
              </h3>
            </div>
          </div>
        </div>
      )}
      <div className='earn'>
        <div className='task-to-do'>
          <h1>Task To DO</h1>
          {tasks.map((task) => (
            <div
              className='tasks'
              key={task.id}
              onClick={() => completeTask(task.id)}
            >
              <div>
                <img src={task.logo} alt='' />
                <p>{task.text}</p>
                <div className='how-much-earn'>
                  <img src={coinLogo} alt='' />
                  <span>+{task.reward.toLocaleString()}</span>
                </div>
              </div>
              {loadingTask === task.id ? (
                <div
                  className='task-spin'
                  id={`l-${task.id}`}
                  style={{ marginTop: "8px" }}
                ></div>
              ) : (
                <IoIosArrowForward id={`a-${task.id}`} />
              )}
            </div>
          ))}
        </div>
        <div className='finshed-task'>
          <h1>Finished Tasks</h1>
          {finishedTasks.map((task) => (
            <div className='tasks' key={task.id}>
              <div>
                <img src={task.logo} alt='' />
                <p>{task.text}</p>
                <div className='how-much-earn'>
                  <img src={coinLogo} alt='' />
                  <span>+{task.reward.toLocaleString()}</span>
                </div>
              </div>
              <img src={check} alt='' />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Earn;
